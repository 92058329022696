* {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.tooltip-class {
    background: #1f1f1f !important;
    opacity: 1 !important;
    max-width: 240px;
    font-size: 14px !important;
    font-weight: 600 !important;
}
